import React from 'react';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ApiConstants from './constants/ApiConstants';
import 'focus-visible';

import './App.css';

import ScreenContainer from './components/screenComponents/ScreenContainer';
import { useMainContext } from './services/Context/Context';
import ScrollToTop from './services/ScrollToTop';

function App() {
  const cache = new InMemoryCache();
  const { state } = useMainContext();

  const client = new ApolloClient({
    uri: `${ApiConstants.API_URL}graphql`,
    cache,
    request: (operation) => {
      operation.setContext({
        headers: {
          authorization: state.user.auth_token
        }
      });
    }
  });

    return (
      <ApolloProvider client={client}>
        <Router>
          <ScrollToTop />
          <ScreenContainer />
        </Router>
      </ApolloProvider>
    );
}

export default App;
