import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Api } from '../../services/Api';
import { refreshToken } from '../../Actions/LoginActions';
import { useMainContext } from '../../services/Context/Context';
import { WP_ROOT_URL } from '../../constants/ApiConstants';
import PopupDoc from '../../services/Modal/ModalComponents/PrivacyPolicy/PopupDoc';

import './style.scss';

const FooterView = () => {
  const [authenticated, setAuthenticated] = useState(false);

  const { state, dispatch } = useMainContext();

  useEffect(() => {
    if (state.user?.token) {
      Api.postValidation(
        `${WP_ROOT_URL}/wp-json/jwt-auth/v1/token/validate`,
        null,
        state.user.token,
      ).then((res) => {
        if (res.message === 'Expired token') {
          console.warn('Expired token');
          const isRefreshed = refreshToken(state, dispatch);
          if (isRefreshed) {
            console.info('Refreshed token!');
            setAuthenticated(true);
          } else {
            console.error('Failed to refresh token! Logging out...');
            setAuthenticated(false);
          }
        } else if (
          res.code === 'jwt_auth_valid_token' &&
          (res.data?.status === 200 || res.statusCode === 200)
        ) {
          setAuthenticated(true);
        } else {
          const isRefreshed = refreshToken(state, dispatch);
          if (isRefreshed) setAuthenticated(true);
          else setAuthenticated(false);
        }
      });
    }
  }, [state?.user?.token]);

  const openDoc = useCallback(
    (type) =>
      dispatch({
        type: 'OPEN_MODAL',
        payload: (
          <PopupDoc
            docSrc={
              type === 'terms'
                ? 'https://app.termly.io/document/terms-of-use-for-ecommerce/f4efc36f-754c-487f-b6f8-df90b753d224'
                : 'https://app.termly.io/document/privacy-policy/7eaa767d-8c99-4b69-b3a7-6a2779e80497'
            }
          />
        ),
      }),
    [],
  );

  return (
    <div className="footer-container">
      <div className="container">
        <div className="row footer-main-row">
          <div className="col-md-7 footer-links-container">
            <div className="row">
              {/* <div className="col-md-5"> */}
              <ul>
                <li>
                  <a href="/">Catalog</a>
                </li>
                {/* <li>
                  <a href={`${WP_ROOT_URL}/exhibits`}>Exhibits</a>
                </li> */}
                <li>
                  <a href={`${WP_ROOT_URL}/for-education`}>For Educators</a>
                </li>
                {/* </ul>
                        </div>
                        <div className="col-md-8">
                            <ul> */}

                <li>
                  <a href={`${WP_ROOT_URL}/contact`}>Contact</a>
                </li>
                <li>
                  <a href={`#`} onClick={() => openDoc('terms')}>
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a href={`#`} onClick={() => openDoc('privacy')}>
                    Privacy Notice
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* <div className="row">
                        <div className="col-md-12 terms-container">
                            <Link to="">Terms & Conditions</Link>
                            <Link to="">Privacy Policy</Link>
                            <span>© 2019 Afriterra Foundation. All rights reserved.</span>
                        </div>
                    </div> */}
          {/* </div> */}
          <div className="col-md-5">
            <div className="donate-container">
              <p>
                With your philanthropic support,
                <br /> The Afriterra Cartographic Library
                <br /> can continue to reach and inspire
                <br /> generations to come.
              </p>
              <a href={`${WP_ROOT_URL}/contact`} className="cta-button">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row all-rights-reserved">All Right Reserved 2021 © Afriterra</div>
      </div>
    </div>
  );
};

export default FooterView;
