import request from 'superagent';

import ApiConstants from '../constants/ApiConstants';
require('superagent-auth-bearer')(request);

// import ApiConstants from '../constants/ApiConstants';
function getUrlForPath(path) {
  return `${ApiConstants.API_URL}${path}`;
}

export var Api = {
  get(path, authToken = '') {
    return new Promise(function (resolve, reject) {
      request
        .get(getUrlForPath(path))
        .set('token', authToken)
        .set('Accept', 'application/json')
        // .set('Access-Control-Allow-Credentials', true)
        .end(function (error, response) {
          if (error) {
            reject(error);
          } else {
            resolve(response.body);
          }
        });
    });
  },
  post(path, data, setState, setLoading) {
    setLoading(true);
    return new Promise((resolve, reject) => {
      request
        .post(path)
        .set('Content-Type', 'application/json')
        .withCredentials(true)
        .send(data)
        .end(function (error, response) {
          if (error) {
            if (setState) {
              if (error.status === 403) {
                setState('Invalid credentials. Please try again.');
              } else {
                setState(error.message);
              }
            }
          } else {
            resolve(response.body);
          }
          setLoading(false);
        });
    });
  },
  refreshPost() {
    return new Promise((resolve, reject) => {
      request
        .post(`${ApiConstants.WP_ROOT_URL}/wp-json/jwt-auth/v1/token`)
        .set('Content-Type', 'application/json')
        .withCredentials(true)
        .send()
        .end(function (error, response) {
          if (error) {
            console.error(error);
            reject(error);
          } else {
            resolve(response.body);
          }
        });
    });
  },
  postValidation(path, data, authToken = null, setState) {
    return new Promise((resolve, reject) => {
      request
        .post(path)
        .set('Content-Type', 'application/json')
        .authBearer(authToken)
        .send(data)
        .end(function (error, response) {
          if (error) {
            if (setState) setState(error.status);
            resolve(error.response.body);
          } else resolve(response.body);
        });
    });
  },
  put(path, data, authToken = null) {
    return new Promise(function (resolve, reject) {
      request
        .put(getUrlForPath(path))
        .authBearer(authToken)
        .type('application/json')
        .send(data)
        .end(function (error, response) {
          if (error) {
            reject(response.body.errors);
          } else {
            resolve(response.body);
          }
        });
    });
  },
  delete(path, authToken = null) {
    return new Promise((resolve, reject) => {
      request
        .delete(getUrlForPath(path))
        .authBearer(authToken)
        .type('application/json')
        .end(function (error, response) {
          if (error) {
            reject(response.body.errors);
          } else {
            resolve(response.body);
          }
        });
    });
  },
};
