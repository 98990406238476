import React, { createContext, useContext, useReducer } from 'react';

import ModalContainer from '../Modal/ModalContainer';

const MainReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        modal: {
          isOpen: true,
          component: action.payload,
        },
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modal: {
          isOpen: false,
          component: null,
        },
      };
    case 'UPDATE_USER':
      return { ...state, userName: action.payload };
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };
    case 'LOGOUT':
      localStorage.removeItem('user');
      return {
        ...state,
        user: {
          loading: false,
          error: false,
        },
      };
    case 'PAGE_NO':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

let user =
  JSON.parse(localStorage.getItem('user'))?.token_version === '1.0.0'
    ? JSON.parse(localStorage.getItem('user'))
    : null;

const initialState = {
  user: {
    ...user,
    loading: false,
    error: '',
    page: 1,
  },
  modal: {
    isOpen: false,
    component: null,
  },
};

const MainContext = createContext([initialState, (obj) => obj]);

function MainContextProvider(props) {
  const [state, dispatch] = useReducer(MainReducer, initialState);
  return <MainContext.Provider value={{ state, dispatch }}>{props.children}</MainContext.Provider>;
}

function useMainContext() {
  return useContext(MainContext);
}

export { MainContext, MainContextProvider, useMainContext };
